// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-chimney-services-js": () => import("./../src/pages/portfolio/chimney-services.js" /* webpackChunkName: "component---src-pages-portfolio-chimney-services-js" */),
  "component---src-pages-portfolio-flat-roofing-js": () => import("./../src/pages/portfolio/flat-roofing.js" /* webpackChunkName: "component---src-pages-portfolio-flat-roofing-js" */),
  "component---src-pages-portfolio-leadwork-js": () => import("./../src/pages/portfolio/leadwork.js" /* webpackChunkName: "component---src-pages-portfolio-leadwork-js" */),
  "component---src-pages-portfolio-roofing-projects-js": () => import("./../src/pages/portfolio/roofing-projects.js" /* webpackChunkName: "component---src-pages-portfolio-roofing-projects-js" */),
  "component---src-pages-portfolio-tiling-and-slating-js": () => import("./../src/pages/portfolio/tiling-and-slating.js" /* webpackChunkName: "component---src-pages-portfolio-tiling-and-slating-js" */),
  "component---src-pages-portfolio-upvc-facias-soffits-and-guttering-js": () => import("./../src/pages/portfolio/upvc-facias-soffits-and-guttering.js" /* webpackChunkName: "component---src-pages-portfolio-upvc-facias-soffits-and-guttering-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-testimonials-js": () => import("./../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

